<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title flexcb">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">首页</a>
        </span>
        <div class="flexcb" v-if="msgList.length">
          <el-carousel
            height="25px"
            style="width:400px"
            direction="vertical"
            :autoplay="true"
            indicator-position="none"
          >
            <el-carousel-item
              v-for="item in msgList"
              :key="item"
              style="width:100%"
            >
              <div
                @click="domsgInfo(item.noticeId)"
                style="width:100%;height:25px;line-height:25px;font-size:14px;cursor: pointer;"
                class="medium flexcb"
              >
                <div class="flexca">
                  <span style="min-width:100px;text-align:right"
                    >【{{
                      $setDictionary("NOTICETYPE", item.noticeType)
                    }}】</span
                  >

                  <el-tooltip
                    effect="dark"
                    :content="item.noticeTitle"
                    placement="bottom"
                  >
                    <span
                      style="max-width:240px;white-space:nowrap;overflow:hidden;"
                    >
                      {{ item.noticeTitle }}
                    </span>
                  </el-tooltip>
                </div>

                <span style="min-width:120px;text-align:right">{{
                  item.noticeTopTime | momentDate
                }}</span>
              </div>
            </el-carousel-item>
          </el-carousel>
          <span
            class="cur-a"
            style="margin-left:10px;cursor: pointer;"
            @click="lookMore"
            >更多>></span
          >
        </div>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="form-box flexdc" style="height:100%">
              <div class="flexcb card-box">
                <div class="card-item flexdc">
                  <span>培训中班级总数(个)</span>
                  <span>{{ dataObj.projectUnderwayTotal || 0 }}</span>
                  <div class="df">
                    <span>开班总数</span>
                    <span>{{ dataObj.projectTotal || 0 }}</span>
                  </div>
                </div>
                <div class="card-item flexdc">
                  <span>进行中学员数量(人次)</span>
                  <span>{{ dataObj.userUnderwayTotal || 0 }}</span>
                  <div class="df">
                    <span>已完成学员数量</span>
                    <span>{{ dataObj.userCompleteTotal || 0 }}</span>
                  </div>
                </div>
                <div class="card-item flexdc">
                  <span>学员总数(人数)</span>
                  <span>{{ dataObj.userTotal || 0 }}</span>
                  <div class="df">
                    <span>已实名认证</span>
                    <span>{{ dataObj.userSmrzTotal || 0 }}</span>
                  </div>
                </div>
              </div>
              <div class="df echerts-box">
                <div style="width:70%;margin-right:4%">
                  <h3 class="title">班级进度情况（共{{ total }}个班级）</h3>
                  <el-table
                    ref="multipleTable"
                    :data="leftData"
                    height="95%"
                    size="small"
                    tooltip-effect="dark"
                    style="width: 100%"
                    :header-cell-style="tableHeader"
                    stripe
                    @row-click="rowClick"
                    @sort-change="sort"
                    :highlight-current-row="true"
                  >
                    <el-table-column
                      label="序号"
                      align="center"
                      type="index"
                      :index="indexMethod"
                      width="50"
                      fixed
                    />
                    <el-table-column
                      label="班级名称"
                      align="left"
                      show-overflow-tooltip
                      prop="projectName"
                      width="260"
                      fixed
                    />
                    <el-table-column
                      label="单位名称"
                      align="left"
                      show-overflow-tooltip
                      prop="compName"
                      width="260"
                    />
                    <el-table-column
                      label="培训类型"
                      align="left"
                      show-overflow-tooltip
                      prop="trainType"
                      width="160"
                    />
                    <el-table-column
                      label="结束时间"
                      align="left"
                      show-overflow-tooltip
                      prop="endDate"
                      width="200"
                      sortable="custom"
                    />
                    <el-table-column
                      label="总人数"
                      align="left"
                      show-overflow-tooltip
                      prop="userTotalCount"
                    />
                    <el-table-column
                      label="已结业人数"
                      align="right"
                      show-overflow-tooltip
                      prop="userCompleteCount"
                    />
                    <el-table-column
                      label="结业率"
                      align="right"
                      show-overflow-tooltip
                      prop="userCompleteProbability"
                    />
                    <Empty slot="empty" />
                  </el-table>
                </div>
                <div style="width: 25%">
                  <h3 class="title">
                    学时未完成学员情况（共{{ rightData.length }}个学员）
                  </h3>
                  <el-table
                    ref="multipleTable1"
                    :data="rightData"
                    height="95%"
                    size="small"
                    tooltip-effect="dark"
                    style="width: 100%"
                    :header-cell-style="rightTableHeader"
                    stripe
                  >
                    <!-- <el-table-column
                      label="序号"
                      align="center"
                      type="index"
                      :index="indexMethod"
                      width="120"
                      fixed
                    /> -->
                    <el-table-column
                      label="学员姓名"
                      align="left"
                      show-overflow-tooltip
                      prop="userName"
                    />
                    <el-table-column
                      label="总学时"
                      align="right"
                      show-overflow-tooltip
                      prop="totalLessonNum"
                    />
                    <el-table-column
                      label="剩余学时"
                      align="right"
                      show-overflow-tooltip
                      prop="unstudyLessonNum"
                    />
                    <Empty slot="empty" />
                  </el-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoginRecommend />
  </div>
</template>
<script>
let echart1 = {};
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import LoginRecommend from "@/components/loginRecommend.vue";
export default {
  name: "OtherHome2",
  components: {
    Empty,
    LoginRecommend
  },
  mixins: [List],
  data() {
    return {
      dataObj: {},
      msgList: [],
      leftData: [],
      scrollStu: true,
      pageNum: 1,
      sheight: 0,
      rightData: [],
      total: 0,
      order:'0',
      rightTableHeader:{
        background: "#5C6BE8",
        color: "#fff",
        borderColor: "#5C6BE8",
        padding:'13.5px 0'
      }
    };
  },
  // created() {
  //   this.init();
  // },
  mounted() {
    // console.log(this.tableHeight);
    this.$refs.multipleTable.bodyWrapper.addEventListener(
      "scroll",
      res => {
        let height = res.target;
        let clientHeight = height.clientHeight;
        let scrollTop = height.scrollTop;
        let scrollHeight = height.scrollHeight;
        this.sheight = scrollHeight - scrollTop - clientHeight;
      },
      true
    );
  },
  watch: {
    sheight(value) {
      if (value <= 5 && value >= -5) {
        if (this.scrollStu) {
          this.scrollStu = false;
          this.getClassProgress(++this.pageNum);
        }
      }
    }
  },
  computed: {},
  methods: {
    sort({ column, prop, order }) {
      if (order == "ascending") {
        this.order = '1';
      } else{
        this.order = '0';
      }
      this.leftData = [];
      this.pageNum = 1;
      this.total = 0;
      this.scrollStu = true;
      this.sheight = 0;
      this.getClassProgress();
    },
    init() {
      //
      this.getCardData();
      this.getnew();
      this.getClassProgress();
    },
    domsgInfo(noticeId) {
      this.$router.push({
        path: "/web/noticationDetail",
        query: {
          noticeId
        }
      });
    },
    lookMore() {
      this.$router.push("/web/systemNotication");
    },
    // 点击行
    rowClick(row, column, event) {
      // ;
      this.getUseruncomplete(row.projectCode);
    },
    // 消息
    getnew() {
      const parmas = {
        pageNum: "1",
        pageSize: "3"
      };
      this.$post("/notice/querySysNoticeCompList", parmas).then(res => {
        if (res.status == "0") {
          this.msgList = res.data.list || [];
        }
      });
    },
    // 学员统计
    getCardData() {
      this.$post("org/dashboard/summarizing").then(res => {
        if (res.status == "0") {
          this.dataObj = res.data;
        }
      });
    },
    // 班级进度情况
    getClassProgress() {
      const params = {
        pageNum: this.pageNum || 1,
        pageSize: 100,
        order:this.order
      };
      this.$post("/org/dashboard/progress", params).then(res => {
        // ;
        if (res.status == "0") {
          const retData = res.data || {};
          this.leftData = [...this.leftData, ...retData.list];
          this.total = retData.total;
          this.scrollStu = this.leftData.length >= retData.total ? false : true;
        }
      });
    },
    getUseruncomplete(projectCode) {
      const params = {
        projectCode: projectCode
      };
      this.$post("/org/dashboard/useruncomplete", params).then(res => {
        // ;
        if (res.status == "0") {
          const retData = res.data || [];
          this.rightData = retData;
        }
      });
    }
  }
};
</script>
<style lang="less"></style>
<style lang="less" scope>
.card-box {
  margin-bottom: 10px;
  .card-item {
    padding: 10px;
    border-radius: 5px;
    min-width: 30%;
    min-height: 150px;
    justify-content: space-around;
    cursor: pointer;
    span {
      line-height: 30px;
      color: #fff;
    }
    div {
      justify-content: space-between;
    }
  }
  & > :nth-child(1) {
    background-image: url(../../assets/first.png);
    background-size: cover;
  }
  & > :nth-child(2) {
    background-image: url(../../assets/second.png);
    background-size: cover;
  }
  & > :nth-child(3) {
    background-image: url(../../assets/third.png);
    background-size: cover;
  }
}
h3.title {
  padding: 0.35rem;
  border-bottom: 0;
}
.echerts-box {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  padding: 10px;
}
.span {
  position: absolute;
  /* left: 50%; */
  /* right: 50%; */
  text-align: center;
  width: 100%;
  top: 63%;
}
</style>
