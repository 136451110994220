<template>
  <!-- 首次登录推荐 -->
  <div class="firstTimeLoginRecommendation">
    <template v-if="dataList.length > 0">
      <el-dialog
        class="dialog1"
        v-for="(item, index) in dataList"
        :key="index"
        :title="item.noticeTitle"
        :visible.sync="item.recommend"
        @close="iGotIt(item.noticeId, index)"
        :close-on-click-modal="false"
        width="60%"
        ><div class="ovy-a">
          <div class="shell">
            <span v-html="item.noticeDetail"></span>
            <el-button
              v-if="item.attachmentUrl"
              type="text"
              size="mini"
              style="color:#FE2120;text-align:left;font-size:16px;margin-top:10px;display: block;"
              @click="downloadTrainDocument(item.attachmentUrl)"
            >
              <i class="el-icon-document-remove"></i> 预览附件
            </el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        class="dialog2"
        title="预览附件"
        :visible.sync="dialog1"
        width="50%"
        top="15px"
        center
        ><div id="pdf-content" style="height: 800px"></div>
      </el-dialog>
    </template>
  </div>
</template>
<script>
import pdf from "pdfobject";
export default {
  name: "firstTimeLoginRecommendation",
  data() {
    return {
      // 首次登录推荐 - 数据
      dataList: [],
      // 查看附件 - loading
      dialog1: false,
    };
  },
  created() {
    this.firstLandingGetData();
  },
  mounted() {},
  computed: {},
  methods: {
    // 首次登录推荐
    firstLandingGetData() {
      this.$post("/notice/queryRecommendNoticeList", {}).then((res) => {
        res.data.map((el) => {
          el.noticeDetail = el.noticeDetail.replace(/&nbsp;/g, "");
        });
        this.dataList = res.data;
      });
    },
    // 预览附件
    downloadTrainDocument(attachmentUrl) {
      this.dialog1 = true;
      this.$nextTick(() => {
        pdf.embed(attachmentUrl, "#pdf-content");
      });
    },
    // 关闭
    iGotIt(noticeId, index) {
      this.$post("/notice/readRecommendNotice", { noticeId }).then((res) => {
        if (res.status == 0) {
          this.dataList[index].recommend = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.firstTimeLoginRecommendation .el-dialog .shell {
  height: 100%;
}
.firstTimeLoginRecommendation .el-dialog .shell span {
  width: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.firstTimeLoginRecommendation .dialog1 /deep/ .el-dialog {
  height: 72%;
}
.firstTimeLoginRecommendation .dialog1 /deep/ .el-dialog__body {
  height: 82%;
}
</style>
